import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import { updateProfile } from "firebase/auth";
import { firebaseFunctions } from "auth/FirebaseAuth";
import { httpsCallable } from "firebase/functions";

export const initialState = {
    loading: false,
    message: "",
    showMessage: false,
    redirect: "",
    token: localStorage.getItem(AUTH_TOKEN) || null,
};

export const firebaseErrorMessages = {
    "auth/email-already-in-use": "Email already in use",
    "auth/invalid-email": "Invalid email",
    "auth/operation-not-allowed": "Operation not allowed",
    "auth/weak-password": "Weak password",
    "auth/user-disabled": "User disabled",
    "auth/user-not-found": "User not found",
    "auth/wrong-password": "Wrong password",
    "auth/invalid-verification-code": "Invalid verification code",
    "auth/invalid-verification-id": "Invalid verification id",
    "auth/code-expired": "Code expired",
    "auth/invalid-credential": "Invalid credential",
    "auth/account-exists-with-different-credential":
        "Account exists with different credential",
    "auth/missing-verification-code": "Missing verification code",
    "auth/missing-verification-id": "Missing verification id",
    "auth/invalid-phone-number": "Invalid phone number",
    "auth/missing-phone-number": "Missing phone number",
    "auth/quota-exceeded": "Quota exceeded",
    "auth/captcha-check-failed": "Captcha check failed",
    "auth/invalid-app-credential": "Invalid app credential",
};

export const getFormattedMessage = (message) => {
    if (firebaseErrorMessages[message]) {
        return firebaseErrorMessages[message];
    }
    return "Internal error";
};

export const extractErrorCode = (message) => {
    const regex = /\(([^)]+)\)/;
    const match = message.match(regex);
    return match ? match[1] : null;
};

export const signIn = createAsyncThunk(
    "auth/signIn",
    async (data, { rejectWithValue }) => {
        const { email, password } = data;
        try {
            const response = await FirebaseService.signInEmailRequest(
                email,
                password
            );
            const user = response.user;
            if (user) {
                const token = response.user.refreshToken;
                localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
                return token;
            } else {
                const errorCode = extractErrorCode(response.message);
                return rejectWithValue(errorCode);
            }
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const signUp = createAsyncThunk(
    "auth/signUp",
    async (data, { rejectWithValue }) => {
        const { email, password, displayName, organisation } = data;
        try {
            const response = await FirebaseService.signUpEmailRequest(
                email,
                password
            );
            const user = response?.user;
            if (user) {
                // Update auth user the displayName
                await updateProfile(user, { displayName });

                // Create user profile on firestore
                const updateUserProfileFunction = httpsCallable(
                    firebaseFunctions,
                    "updateUserProfile"
                );
                await updateUserProfileFunction({
                    displayName: user.displayName,
                });

                // Create new organisation
                const createOrganisationFunction = httpsCallable(
                    firebaseFunctions,
                    "createOrganisation"
                );
                const responseCreateOrganisation =
                    await createOrganisationFunction({
                        name: organisation,
                        owner: {
                            [user.uid]: user.displayName,
                        },
                        email: user.email,
                    });

                // Update user with the organisation
                await updateUserProfileFunction({
                    organisations: {
                        [responseCreateOrganisation.data.id]: {
                            name: organisation,
                        },
                    },
                });

                const token = response.user.refreshToken;
                localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
                return token;
            } else {
                const errorCode = extractErrorCode(response.message);
                return rejectWithValue(errorCode);
            }
        } catch (err) {
            console.log("Error: ", err);
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
    const response = await FirebaseService.signOutRequest();
    localStorage.removeItem(AUTH_TOKEN);
    return response.data;
});

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.loading = false;
            state.redirect = "/";
            state.token = action.payload;
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload;
            state.showMessage = true;
            state.loading = false;
        },
        hideAuthMessage: (state) => {
            state.message = "";
            state.showMessage = false;
        },
        signOutSuccess: (state) => {
            state.loading = false;
            state.token = null;
            state.redirect = "/";
        },
        showLoading: (state) => {
            state.loading = true;
        },
        signInSuccess: (state, action) => {
            state.loading = false;
            state.token = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.loading = true;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.loading = false;
                state.redirect = "/";
                state.token = action.payload;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.message = getFormattedMessage(action.payload);
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(signOut.fulfilled, (state) => {
                state.loading = false;
                state.token = null;
                state.redirect = "/";
            })
            .addCase(signOut.rejected, (state) => {
                state.loading = false;
                state.token = null;
                state.redirect = "/";
            })
            .addCase(signUp.pending, (state) => {
                state.loading = true;
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.loading = false;
                state.redirect = "/";
                state.token = action.payload;
            })
            .addCase(signUp.rejected, (state, action) => {
                state.message = getFormattedMessage(action.payload);
                console.log(action.payload);
                state.showMessage = true;
                state.loading = false;
            });
    },
});

export const {
    authenticated,
    showAuthMessage,
    hideAuthMessage,
    signOutSuccess,
    showLoading,
    signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
