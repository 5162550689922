import { initializeApp } from "firebase/app"
import { getFirestore } from 'firebase/firestore/lite';
import { 
	getAuth, 
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut,
	signInWithPopup,
	GoogleAuthProvider,
	FacebookAuthProvider,
} from 'firebase/auth'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getFunctions } from 'firebase/functions';


import firebaseConfig from 'configs/FirebaseConfig';
import { getStorage } from "firebase/storage";

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const currentUser = auth.currentUser
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const firebaseFunctions = getFunctions(firebaseApp, firebaseConfig.region);
const storage = getStorage(firebaseApp);

export {
	db,
	auth,
	currentUser,
	googleAuthProvider,
	GoogleAuthProvider,
	facebookAuthProvider,
	FacebookAuthProvider,
	signInWithEmailAndPassword,
	signOut,
	signInWithPopup,
	createUserWithEmailAndPassword,
	firebaseFunctions,
	storage,
};