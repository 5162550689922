import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import store from "./store";
import history from "./history";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./lang";
import { AuthProvider } from "contexts/Auth";
import { CurrentOrganisationProvider } from "contexts/Organisations";
import { SpinnerProvider } from "contexts/Spinner";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      <SpinnerProvider>
        <AuthProvider>
          <CurrentOrganisationProvider>
            <Provider store={store}>
              <BrowserRouter history={history}>
                <ThemeSwitcherProvider
                  themeMap={themes}
                  defaultTheme={THEME_CONFIG.currentTheme}
                  insertionPoint="styles-insertion-point"
                >
                  <Layouts />
                </ThemeSwitcherProvider>
              </BrowserRouter>
            </Provider>
          </CurrentOrganisationProvider>
        </AuthProvider>
      </SpinnerProvider>
    </div>
  );
}

export default App;
