/**
 * @TODO Move this to a .env file
*/
const FirebaseConfig = {
  apiKey: 'AIzaSyBE7bGL3GG7z3nzI3zMCR68xfAEUEkb6s0',
  authDomain: 'allied-proms.firebaseapp.com',
  databaseURL: '',
  projectId: 'allied-proms',
  storageBucket: 'allied-proms.appspot.com',
  messagingSenderId: '1019442303870',
  appId: '1:1019442303870:web:4d93cd169a3c47c709992f',
  measurementId: 'G-RZBSCYL6TD',
  region: 'australia-southeast1',
};

export default FirebaseConfig

