import React from "react";
import {
    AUTH_PREFIX_PATH,
    APP_PREFIX_PATH,
    FORM_PREFIX_PATH,
} from "configs/AppConfig";

export const formRoutes = [
    {
        key: "pages.answer-questionnaire",
        path: `${FORM_PREFIX_PATH}/answer-questionnaire/:id/:status`,
        component: React.lazy(() =>
            import("views/app-views/pages/answerQuestionnaire")
        ),
        meta: {
            blankLayout: true,
        },
    },
];

export const publicRoutes = [
    {
        key: "login",
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() =>
            import("views/auth-views/authentication/login")
        ),
    },
    {
        key: "register",
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() =>
            import("views/auth-views/authentication/register")
        ),
    },
    {
        key: "forgot-password",
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() =>
            import("views/auth-views/authentication/forgot-password")
        ),
    },
];

export const protectedRoutes = [
    {
        key: "dashboard.default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() =>
            import("views/app-views/dashboards/default")
        ),
    },
    {
        key: "pages.patients",
        path: `${APP_PREFIX_PATH}/patients/*`,
        component: React.lazy(() => import("views/app-views/pages/patients")),
    },
    {
        key: "pages.email-templates",
        path: `${APP_PREFIX_PATH}/email-templates`,
        component: React.lazy(() =>
            import("views/app-views/pages/emailTemplates")
        ),
    },
    {
        key: "pages.email-templates-edit",
        path: `${APP_PREFIX_PATH}/email-templates/edit/:id`,
        component: React.lazy(() =>
            import("views/app-views/pages/emailTemplates/manageTemplate")
        ),
    },
    {
        key: "pages.waiting-list",
        path: `${APP_PREFIX_PATH}/waiting-list/*`,
        component: React.lazy(() =>
            import("views/app-views/pages/waitingList")
        ),
    },
    {
        key: "pages.waiting-list",
        path: `${APP_PREFIX_PATH}/waiting-list/*`,
        component: React.lazy(() =>
            import("views/app-views/pages/waitingList")
        ),
    },
    {
        key: "pages.email-templates-new",
        path: `${APP_PREFIX_PATH}/email-templates/new`,
        component: React.lazy(() =>
            import("views/app-views/pages/emailTemplates/manageTemplate")
        ),
    },
    {
        key: "pages.organisation",
        path: `${APP_PREFIX_PATH}/organisation/*`,
        component: React.lazy(() =>
            import("views/app-views/pages/organisation")
        ),
    },
    {
        key: "pages.setting",
        path: `${APP_PREFIX_PATH}/pages/setting/*`,
        component: React.lazy(() => import("views/app-views/pages/setting")),
    },
    {
        key: "pages.admin",
        path: `${APP_PREFIX_PATH}/admin/*`,
        component: React.lazy(() => import("views/app-views/pages/admin")),
    },
];
