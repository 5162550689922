import {
  auth,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  firebaseFunctions,
} from "auth/FirebaseAuth";
import { httpsCallable } from "firebase/functions";

const FirebaseService = {};

FirebaseService.signInEmailRequest = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((err) => err);
};

FirebaseService.signOutRequest = async () =>
  await signOut(auth)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
  await createUserWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((err) => err);

export const updateUserProfile = async (userProfileData) => {
  try {
    const updateUserProfileFunction = httpsCallable(
      firebaseFunctions,
      "updateUserProfile"
    );
    const result = await updateUserProfileFunction(userProfileData);
    return result;
  } catch (error) {
    console.error(error);
    throw new Error("Error updating user profile");
  }
};

export const updateOrganisation = async (organisationId, organisationData) => {
  try {
    const updateOrganisationFunction = httpsCallable(
      firebaseFunctions,
      "updateOrganisation"
    );
    const result = await updateOrganisationFunction({
      organisationId,
      organisationData,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw new Error("Error updating organization");
  }
};

export const getOrganisationUsers = async (organisationId) => {
  try {
    const getOrganisationUsersFunction = httpsCallable(
      firebaseFunctions,
      "getOrganisationUsers"
    );
    const result = await getOrganisationUsersFunction({ organisationId });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error getting organisation users");
  }
};

export const removeUserFromOrganisation = async (organisationId, email) => {
  try {
    const removeUserFromOrganisationFunction = httpsCallable(
      firebaseFunctions,
      "removeUserFromOrganisation"
    );
    const result = await removeUserFromOrganisationFunction({
      organisationId,
      email,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw new Error("Error removing user from organisation");
  }
};

export const inviteUser = async (name, email, organisation) => {
  try {
    const inviteUserFunction = httpsCallable(firebaseFunctions, "inviteUser");
    const result = await inviteUserFunction({
      name,
      email,
      organisation,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw new Error("Error inviting user");
  }
};

export const getInvitedUsers = async (organisationId, userEmail) => {
  try {
    const getInvitedUsersFunction = httpsCallable(
      firebaseFunctions,
      "getInvitedUsers"
    );
    const result = await getInvitedUsersFunction({
      organisationId,
      userEmail,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error getting invited users");
  }
};

export const reSendInviteUser = async (inviteId) => {
  try {
    const reSendInviteUserFunction = httpsCallable(
      firebaseFunctions,
      "reSendInviteUser"
    );
    const result = await reSendInviteUserFunction({ inviteId });
    return result;
  } catch (error) {
    console.error(error);
    throw new Error("Error re-sending invite");
  }
};

export const acceptInviteUser = async (userEmail, organisationId = "") => {
  try {
    const acceptInviteUserFunction = httpsCallable(
      firebaseFunctions,
      "acceptInviteUser"
    );
    const result = await acceptInviteUserFunction({
      userEmail,
      organisationId,
    });
    return result;
  } catch (error) {
    console.error(error);
    if (organisationId) {
      throw new Error("Error accepting invite");
    }
  }
};

export const rejectInviteUser = async (userEmail, organisationId = "") => {
  try {
    const rejectInviteUserFunction = httpsCallable(
      firebaseFunctions,
      "rejectInviteUser"
    );
    const result = await rejectInviteUserFunction({
      userEmail,
      organisationId,
    });
    return result;
  } catch (error) {
    console.error(error);
    if (organisationId) {
      throw new Error("Error rejecting invite");
    }
  }
};

export const createWaitingList = async (organisationId, patients) => {
  try {
    const createWaitingListFunction = httpsCallable(
      firebaseFunctions,
      "createWaitingList"
    );
    const result = await createWaitingListFunction({
      organisationId,
      patients,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw new Error("Error saving waiting list");
  }
};

export const removeWaitingList = async (organisationId) => {
  try {
    const removeWaitingListFunction = httpsCallable(
      firebaseFunctions,
      "removeWaitingList"
    );
    const result = await removeWaitingListFunction({ organisationId });
    return result;
  } catch (error) {
    console.error(error);
    throw new Error("Error when deleting waiting list");
  }
};

export const getSpecialties = async () => {
  try {
    const getSpecialtiesFunction = httpsCallable(
      firebaseFunctions,
      "getSpecialties"
    );
    const result = await getSpecialtiesFunction();
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error getting specialties");
  }
};

export const getTotalToBePaid = async (selectedSpecialties) => {
  try {
    const getTotalToBePaidFunction = httpsCallable(
      firebaseFunctions,
      "getTotalToBePaid"
    );
    const result = await getTotalToBePaidFunction({ selectedSpecialties });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error getting total to be paid");
  }
};

export const createStripeSession = async (
  organisationId,
  stripeCustomerId,
  amountOfSpecialties
) => {
  try {
    const createStripeSession = httpsCallable(
      firebaseFunctions,
      "createStripeSession"
    );

    const createStripeSessionResult = await createStripeSession({
      organisationId,
      amountOfSpecialties,
      stripeId: stripeCustomerId,
    });

    return createStripeSessionResult.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error creating stripe session");
  }
};

export const getSubscriptionTierPrices = async () => {
  try {
    const getSubscriptionTierPricesFunction = httpsCallable(
      firebaseFunctions,
      "getSubscriptionTierPrices"
    );
    const result = await getSubscriptionTierPricesFunction();
    return result.data.tierPrices;
  } catch (error) {
    console.error(error);
    throw new Error("Error getting subscription tier prices");
  }
};

export const getSubscriptionStatus = async (organisationId) => {
  try {
    const checkActiveSubscription = httpsCallable(
      firebaseFunctions,
      "checkActiveSubscription"
    );
    const result = await checkActiveSubscription({ organisationId });
    return result.data.subscriptionStatus;
  } catch (error) {
    console.error(error);
    throw new Error("Error getting subscription status");
  }
};

export const cancelSubscriptionFunction = async (organisationId) => {
  try {
    const cancelSubscription = httpsCallable(
      firebaseFunctions,
      "cancelSubscription"
    );
    const result = await cancelSubscription({ organisationId });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error cancelling subscription");
  }
};

export const updateSubscriptionFunction = async (
  organisationId,
  specialties
) => {
  try {
    const updateSubscription = httpsCallable(
      firebaseFunctions,
      "updateSubscription"
    );
    const result = await updateSubscription({
      organisationId,
      specialties,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error updating subscription");
  }
};

export const getInvoices = async (organisationId) => {
  try {
    const getInvoices = httpsCallable(firebaseFunctions, "getInvoices");
    const result = await getInvoices({ organisationId });
    return result.data.invoices;
  } catch (error) {
    console.error(error);
    throw new Error("Error getting invoices");
  }
};

export const getSpecialtiesConfigs = async (organisationId) => {
  try {
    const getSpecialtiesConfigsFunction = httpsCallable(
      firebaseFunctions,
      "getSpecialtiesConfigs"
    );
    const result = await getSpecialtiesConfigsFunction({ organisationId });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error getting specialties emails");
  }
};

export const updateSpecialtiesConfigs = async (
  organisationId,
  specialtiesConfigs
) => {
  try {
    const updateSpecialtiesConfigsFunction = httpsCallable(
      firebaseFunctions,
      "updateSpecialtiesConfigs"
    );
    const result = await updateSpecialtiesConfigsFunction({
      organisationId,
      specialtiesConfigs,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error updating specialties emails");
  }
};

export const updatePromFormsConfigs = async (
  organisationId,
  promFormsConfigs
) => {
  try {
    const updatePromFormsConfigsFunction = httpsCallable(
      firebaseFunctions,
      "updatePromFormsConfigs"
    );
    const result = await updatePromFormsConfigsFunction({
      organisationId,
      promFormsConfigs: promFormsConfigs,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error updating prom forms configs");
  }
};

export const getEmailTemplates = async (organisationId, templateId) => {
  try {
    const getEmailTemplatesFunction = httpsCallable(
      firebaseFunctions,
      "getEmailTemplates"
    );
    const result = await getEmailTemplatesFunction({
      organisationId,
      templateId,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error getting email templates");
  }
};

export const getEmailTemplateTags = async () => {
  try {
    const getEmailTemplateTagsFunction = httpsCallable(
      firebaseFunctions,
      "getEmailTemplateTags"
    );
    const result = await getEmailTemplateTagsFunction();
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error getting email template tags");
  }
};

export const updateEmailTemplate = async (organisationId, templateData) => {
  try {
    const updateEmailTemplateFunction = httpsCallable(
      firebaseFunctions,
      "updateEmailTemplate"
    );
    const result = await updateEmailTemplateFunction({
      organisationId,
      templateData,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error updating email template");
  }
};

export const removeTemplate = async (organisationId, templateId) => {
  try {
    const removeTemplateFunction = httpsCallable(
      firebaseFunctions,
      "removeTemplate"
    );
    const result = await removeTemplateFunction({
      organisationId,
      templateId,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error removing email template");
  }
};

export const sendFormToPatient = async (
  organisation,
  patients,
  specialty,
  formId
) => {
  try {
    const sendFormToPatientFunction = httpsCallable(
      firebaseFunctions,
      "sendFormToPatient"
    );
    const result = await sendFormToPatientFunction({
      organisation,
      patients,
      specialty,
      formId,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error sending patient prom form");
  }
};

export const getWaitingList = async (organisationId) => {
  try {
    const getWaitingListFunction = httpsCallable(
      firebaseFunctions,
      "getWaitingList"
    );
    const result = await getWaitingListFunction({
      organisationId,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error returning waiting list");
  }
};

export const getPromForms = async (organisationId, promId = "") => {
  try {
    const getPromFormsFunction = httpsCallable(
      firebaseFunctions,
      "getPromForms"
    );
    const result = await getPromFormsFunction({
      organisationId,
      id: promId,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error returning prom forms");
  }
};

export const getProcessingAnswerRequestData = async (answerRequestId) => {
  try {
    const getProcessingAnswerRequestDataFunction = httpsCallable(
      firebaseFunctions,
      "getProcessingAnswerRequestData"
    );
    const result = await getProcessingAnswerRequestDataFunction({
      answerRequestId,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getPromFormAnswerLink = async (
  formId,
  specialty,
  clinikoId,
  organisation,
  isPublicUrl = true
) => {
  try {
    const getPromFormAnswerLinkFunction = httpsCallable(
      firebaseFunctions,
      "getPromFormAnswerLink"
    );
    const result = await getPromFormAnswerLinkFunction({
      formId,
      specialty,
      clinikoId,
      organisation,
      isPublicUrl,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error returning answer request data");
  }
};

export const getPatientsData = async (clinikoApiKey, firstName, lastName) => {
  try {
    const getPatientsDataFunction = httpsCallable(
      firebaseFunctions,
      "getPatients"
    );

    const result = await getPatientsDataFunction({
      clinikoApiKey,
      firstName,
      lastName,
    });

    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error returning patients data");
  }
};

export const getOrganisationPromFormsConfigs = async (organisationId) => {
  try {
    const getOrganisationPromFormsConfigsFunction = httpsCallable(
      firebaseFunctions,
      "getOrganisationPromFormsConfigs"
    );
    const result = await getOrganisationPromFormsConfigsFunction({
      organisationId,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error returning organisation prom forms configs");
  }
};

export const getOrganisations = async () => {
  try {
    const getOrganisationsFunction = httpsCallable(
      firebaseFunctions,
      "getOrganisations"
    );
    const result = await getOrganisationsFunction();
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error returning organisations");
  }
};

export const removeSpecialty = async (specialty) => {
  try {
    const removeSpecialtyFunction = httpsCallable(
      firebaseFunctions,
      "removeSpecialty"
    );
    const result = await removeSpecialtyFunction({ specialty });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error removing specialty");
  }
};

export const addSpecialty = async (specialty) => {
  try {
    const addSpecialtyFunction = httpsCallable(
      firebaseFunctions,
      "addSpecialty"
    );

    const result = await addSpecialtyFunction({ specialty });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error adding specialty");
  }
};

export const removeProm = async (id) => {
  try {
    const removePromFunction = httpsCallable(firebaseFunctions, "removeProm");
    const result = await removePromFunction({ id });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error removing prom");
  }
};

export const savePromForm = async (promForm) => {
  try {
    const savePromFormFunction = httpsCallable(
      firebaseFunctions,
      "savePromForm"
    );

    const result = await savePromFormFunction({ promForm });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error saving prom form");
  }
};

export default FirebaseService;
