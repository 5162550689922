import styled from '@emotion/styled';
import Loading from 'components/shared-components/Loading';
import React, { createContext, useMemo, useState } from 'react';

export const SpinnerContext = createContext({});

const BoxLoading = styled.div(() => ({
	position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.8)',
    zIndex: '9999',
}))

export const SpinnerProvider = ({ children }) => {
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);

  const spinnerValue = useMemo(
    () => ({ isLoadingSpinner, setIsLoadingSpinner }),
    [isLoadingSpinner, setIsLoadingSpinner],
  );

  return (
    <SpinnerContext.Provider value={spinnerValue}>
        {isLoadingSpinner &&
            <BoxLoading>
                <Loading></Loading>
            </BoxLoading>
        }
        {children}
    </SpinnerContext.Provider>
  );
};

export const useSpinnerContext = () => {
  const context = React.useContext(SpinnerContext);
  if (!context) {
    throw new Error("useSpinnerContext must be used within a AuthProvider");
  }
  return context;
};