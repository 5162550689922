import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { onAuthStateChanged } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { auth, firebaseFunctions } from "auth/FirebaseAuth";
import {
  acceptInviteUser,
  updateUserProfile as updateUserProfileDb,
} from "services/FirebaseService";
import { isEmpty } from "lodash";
import { message } from "antd";
import { useSpinnerContext } from "./Spinner";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [authUser, setAuthUser] = useState(null);
  const {setIsLoadingSpinner} = useSpinnerContext();

  const fetchUserData = useCallback(async (retries = 0) => {
    try {
      setIsLoadingSpinner(true)
      const getUserData = httpsCallable(firebaseFunctions, "getUserData");
      const userData = await getUserData();
      setUser(userData.data);
      setIsLoadingSpinner(false)
    } catch (error) {
      console.error(`Failed to get user data`);
      message.error({
        content: "Failed to load User!",
        key: "loadingFetchUserData",
        duration: 3,
      });
      throw new Error("Failed to get user data");
    }
  }, []);

  const updateUserProfile = useCallback(
    async (userDataToUpdate) => {
      try {
        message.loading({
          content: "Updating User...",
          key: "fetchUserData",
        });
        await updateUserProfileDb(userDataToUpdate);
        await fetchUserData();
      } catch (error) {
        console.error(error);
        throw new Error("Failed to update user data");
      }
    },
    [fetchUserData]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      setAuthUser(authUser);
      if (!authUser) {
        setUser(null);
        return;
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (authUser?.uid && window.location.pathname !== "/auth/register") {
      fetchUserData();
    }
  }, [fetchUserData, authUser]);

  useEffect(() => {
    if (!user) return;

    // Check if user has pending invite and only one organization or no organizations
    if (user?.hasPendingInvite && isEmpty(user.organisations)) {
      // This automatically accepts the invite and fetches the user data again
      /**
       * @TODO PUT A LOADING STATE HERE
       */
      acceptInviteUser(user.email).then(fetchUserData);
    }
  }, [user, fetchUserData]);

  const values = useMemo(
    () => ({ user, fetchUserData, updateUserProfile }),
    [user, fetchUserData, updateUserProfile]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
};
